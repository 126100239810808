import Transcription from "./Transcription";
import { ITranscription } from "./Transcription/interface";
import { ISummary } from "./Summary/interface";
import Summary from "./Summary";
import Mark from "./Mark";
import { IMark } from "./Mark/interface";
import { IAta } from "./Ata/interface";
import Ata from "./Ata";
import TranscriptionFollowUp from "./TranscriptionFollowUp";

export const menu = ({
  transcription,
  summary,
  mark,
  ata,
  videoRef,
  isAISummaryEnabled,
  isSynchronizedTextEnabled,
  disabledButton
}: {
  transcription: ITranscription;
  summary: ISummary;
  mark: IMark;
  ata: IAta;
  videoRef: React.RefObject<HTMLVideoElement>;
  isAISummaryEnabled: boolean;
  isSynchronizedTextEnabled: boolean;
  disabledButton: boolean;
}) => {
  return (() => {
    const menusList = [];
    const bodyList = [];
    let id = 0;

    menusList.push({ id, value: "Marcações" });
    bodyList.push({ id, Component: () => <Mark {...mark} /> });
    id++;

    menusList.push({ id, value: "Transcrição" });
    bodyList.push({
      id,
      Component: () => (
        <Transcription
          {...transcription}
          isSynchronizedTextEnabled={isSynchronizedTextEnabled}
          disabledButton={disabledButton}
        />
      ),
    });
    id++;

    if (isSynchronizedTextEnabled) {
      menusList.push({ id, value: "Texto Sincronizado" });
      bodyList.push({
        id,
        Component: () => (
          <TranscriptionFollowUp {...transcription} videoRef={videoRef} />
        ),
      });
      id++;
    }

    if (isAISummaryEnabled) {
      menusList.push({ id, value: "Resumo" });
      bodyList.push({ id, Component: () => <Summary {...summary} /> });
      id++;
    }

    menusList.push({ id, value: "Ata" });
    bodyList.push({ id, Component: () => <Ata {...ata} /> });

    return {
      pageDefault: 0,
      menusList,
      bodyList,
    };
  })();
};
