import { ICheckboxList } from "../interface";

export const documentTypeList = [
  { id: 1, name: "Ata" },
  { id: 2, name: "Transcrição" },
];

export const checkboxList: Array<ICheckboxList> = [
  { id: 3, label: "Número do audiência", value: "ProcedureNumber" },
  { id: 4, label: "Comarca", value: "Place" },
  { id: 5, label: "Data de abertura", value: "ExpectedDate" },
  { id: 6, label: "Duração", value: "Duration" },
  { id: 7, label: "Responsável", value: "AuthorityName" },
  { id: 8, label: "Prazo final", value: "ProcedureFinishDate" },
  { id: 10, label: "Tipo de audiencia", value: "HearingTypeName" },
  { id: 11, label: "Transcrição", value: "Transcription" },
];
