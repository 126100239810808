import { useEffect, useRef } from "react";
import { InputGeneral } from "../../../../components/Input";
import {
  Container,
  ContainerInputs,
  ContainerSwitch,
} from "../../StyleCSS/bodyCreateEdit";
import { DropdownGlobal } from "../../../../components/DropdownGlobal";
import { Editor } from "@tinymce/tinymce-react";
import { editorInit } from "./tiny";
import {
  KeyWordsTitle,
  BottomContainer,
  ContainerCheckboxList,
  ContainerTextEditor,
  ContainerBody,
} from "./styles";
import { CheckBoxGlobal } from "../../../../components/CheckBoxGlobal";
import { Switch } from "@kentarepo/kcf-assets";
import { ICheckboxList, ICreateEditCustomerTemplate } from "../interface";
import { checkboxList, documentTypeList } from "./constants";



export const CreateEditCustomerTemplate = ({
  fieldError,
  setFieldError,
  title,
  description,
  template,
  type,
  actived,
  setTitle,
  keywords,
  setKeywords,
  setType,
  setTemplate,
  setDescription,
  setActived,
}: ICreateEditCustomerTemplate): JSX.Element => {
  const refTiny = useRef<any>(null);

  const onSelectKeyword = (keyword: string) => {
    const isSelected = keywords.includes(keyword);
    const isTranscriptionBlocked = type === 1 && keywords.includes('Transcription');
    const placeholder = `&lt;&lt;${keyword}&gt;&gt;`;
    const wrappedKeyword = `<strong>${placeholder}</strong>&nbsp`;

    if (isSelected || isTranscriptionBlocked) {
      setTemplate(template.replaceAll(placeholder, ""));
      setKeywords(keywords.filter(k => k !== keyword));
      return;
    }

    const cleanTemplate = template.trim();
    const hasParagraph = cleanTemplate.startsWith("<p>") && cleanTemplate.endsWith("</p>");

    const newContent = hasParagraph
      ? cleanTemplate.replace("</p>", `${wrappedKeyword}</p>`)
      : `${cleanTemplate}${wrappedKeyword}`;

    setTemplate(newContent);
    setKeywords([...keywords, keyword]);
  };

  useEffect(() => {
    if (type === 1 && keywords.includes('Transcription')) {
      setTemplate(
        template.replace(
          `<strong>&lt;&lt;Transcription&gt;&gt;</strong>&nbsp;`,
          ""
        )
      );
      const newArray = keywords.filter(item => item !== 'Transcription')
      setKeywords(newArray)
    }
  }, [type])

  const onChangeTemplate = (e: string) => {
    const newKeywords: string[] = [];

    for (const checkbox of checkboxList) {
      if (e.includes(`&lt;&lt;${checkbox.value}&gt;&gt;`)) {
        newKeywords.push(checkbox.value);
      }
    }

    setKeywords(Array.from(new Set(newKeywords)));
    setTemplate(e);
  };


  const newCheckList =
    type === 1
      ? checkboxList.filter((x: ICheckboxList) => x?.id !== 11)
      : checkboxList;

  return (
    <>
      <Container>
        <ContainerBody>
          <ContainerSwitch>
            <Switch
              label="Ativo"
              checked={actived}
              onChange={() => setActived(!actived)}
            />
          </ContainerSwitch>
          <ContainerInputs>
            <DropdownGlobal
              labelName="Tipo de documento"
              defaultValue={
                type ? documentTypeList.find((t) => t?.id === type)?.name : ""
              }
              setSelected={(e: any) => {
                setFieldError({
                  template: fieldError.template,
                  type: false,
                  description: fieldError.description,
                  title: fieldError.title,
                });
                setType(e);
              }}
              required
              showDefaultValue={!!type}
              marginRight="10px"
              height="24px"
              width={"195px"}
              options={documentTypeList}
              error={fieldError.type}
            />
            <InputGeneral
              error={fieldError.title}
              value={title}
              onChange={(e) => {
                setTitle(e);
                setFieldError({
                  template: fieldError.template,
                  type: fieldError.type,
                  description: fieldError.description,
                  title: false,
                });
              }}
              type="text"
              label="Título"
              required={true}
              marginBottomContainer="23px"
            />
          </ContainerInputs>
          <ContainerInputs>
            <InputGeneral
              error={fieldError.description}
              value={description}
              onChange={(e) => {
                setDescription(e);
                setFieldError({
                  template: fieldError.template,
                  type: fieldError.type,
                  description: false,
                  title: fieldError.title,
                });
              }}
              type="text"
              label="Descrição"
              startFocus={false}
              required
            />
          </ContainerInputs>
          <ContainerTextEditor error={fieldError.template}>
            <Editor
              ref={refTiny}
              inline={false}
              onEditorChange={(e) => {
                setFieldError({
                  template: false,
                  type: fieldError.type,
                  description: fieldError.description,
                  title: fieldError.title,
                });
                onChangeTemplate(e);
              }}
              value={template}
              apiKey={import.meta.env.VITE_APP_TINY_KEY}
              onInit={(editor) => (refTiny.current = editor)}
              init={{ ...editorInit, newline_behavior: 'linebreak' }}
              onKeyDown={(event) => {
                if ((event.ctrlKey || event.metaKey) && (event.key === "c")) {
                  event.preventDefault();
                  alert("Não é possivel copiar esse texto");
                }
              }}
              onCopy={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
            />
          </ContainerTextEditor>
          <BottomContainer>
            <KeyWordsTitle>Palavras chaves</KeyWordsTitle>
            <ContainerCheckboxList>
              {newCheckList.map((item: ICheckboxList) => (
                <CheckBoxGlobal
                  checked={keywords.includes(item.value)}
                  id={item.id}
                  label={item.label}
                  onChecked={() => onSelectKeyword(item.value)}
                />
              ))}
            </ContainerCheckboxList>
          </BottomContainer>
        </ContainerBody>
      </Container>
    </>
  );
};
