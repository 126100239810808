export const stringFormat = {
  verifyExistNameInArray: (list: Array<any>, name: string, id?: number | undefined): boolean => {
    const finder = list.find(
      (element: any) =>
        element.name.replace(/\s/g, '').toLowerCase() === name.replace(/\s/g, '').toLowerCase() && element?.id !== id,
    );

    if (finder) {
      return true;
    }
    return false;
  },

  isEmptyOrSpaces: (str: string) => {
    return str === null || str.match(/^ *$/) !== null;
  },
  isVisuallyEmpty: (text?: string | null) => {
    if (!text) return true;
    const cleaned = text.replace(/(&nbsp;|\u00a0|\s)/g, '');
    return cleaned === '';
  }
};
