import React, { Fragment, useEffect, useRef, useState } from "react";
import sanitizeHtml from 'sanitize-html';
import { Editor } from "@tinymce/tinymce-react";
import { Button } from "@kentarepo/kcf-assets";
import { ITranscription } from "./interface";
import { TranscriptionStatus } from "../utils";
import { editorInit } from "../../../../services/utils/editor.config";
import { DropdownGlobal } from "../../../../components/DropdownGlobal";
import { usePermissions } from "../../../../hooks/permission";
import { FORMS_HEARING } from "../../../../utils/entities/permission";
import { customerTemplateService } from "../../../../services/costumerTemplate";
import { LoadSpinner } from "../../../LoadSpinner";
import {
  Container,
  FooterContainer,
  ButtonContainer,
  ContainerTiny,
  OptionListContainer,
  ContainerItem,
  Circle,
  ItemTitle,
  ContainerTemplate,
  StatusContainer,
  ContainerStatus,
} from "./styles";

const optionList = [
  { id: 1, value: "Manual", type: "manual" },
  { id: 2, value: "Automática", type: "automatico" },
];

const Transcription: React.FC<ITranscription> = ({
  buttonTitle,
  buttonFunction,
  disabledButton,
  status,
  ataRef,
  transcription,
  modelList,
  transcriptionSelect,
  setTranscriptionSelect,
  selectTemplate,
  setSelectTemplate,
  showModelInput,
  hearingId,
  setShowModelInput,
  tryAgain,
  hearingFileId,
  loadingTranscription,
  isSynchronizedTextEnabled,
}) => {
  const [tinyText, setTinyText] = useState("");
  const { export: exportDownloads } = usePermissions(
    FORMS_HEARING.HEARING_FILE
  );

  const selectedModel =
    transcription && transcription[0] && modelList.length
      ? modelList.find(
          (x: any) => x?.id === transcription[0].customerTemplateId
        )
      : { name: "Selecione o modelo", template: "" };

  const blockChangeTemplate = useRef<boolean>(false);
  const prevSelectTemplateRef = useRef(selectTemplate);
  const statusError = status && status > 6 && status < 15;

  useEffect(() => {    
    if (transcription && transcription.length) {
      setTinyText(
        selectTemplate ? selectTemplate.template : transcription[0].content
      );

      blockChangeTemplate.current = true;
    }
  }, [transcription]);

  useEffect(() => {
    if (prevSelectTemplateRef.current !== selectTemplate) {
      (async () => {
        if (
          !(transcription && transcription.length && transcription[0]?.content)
        ) {
          if (selectTemplate && selectTemplate.template) {
            const { body } =
              await customerTemplateService.SetKeywordsByHearingFile({
                hearingFileId: hearingFileId,
                customerTemplateId: selectTemplate?.id || 0,
              });
            setTinyText(body);
          }
        }
      })();
      prevSelectTemplateRef.current = selectTemplate;
    }
  }, [selectTemplate, transcription, hearingId]);

  return (
    <Container>
      <ContainerTiny showModelInput={showModelInput}>
        {loadingTranscription ? (
          <LoadSpinner loading={loadingTranscription} />
        ) : status === 6 ? (
          <Fragment>
            {showModelInput && (
              <ContainerTemplate>
                <DropdownGlobal
                  labelName="Modelo"
                  options={modelList}
                  defaultValue={
                    selectTemplate ? selectTemplate?.name : selectedModel?.name
                  }
                  showDefaultValue
                  width="320px"
                  setSelected={setSelectTemplate}
                  wantAnObject
                  disabled={blockChangeTemplate.current}
                />
              </ContainerTemplate>
            )}
            <Editor
              apiKey={import.meta.env.VITE_APP_TINY_KEY}
              onInit={(_, editor) => {
                (ataRef.current = editor), setShowModelInput(true);
              }}
              init={{...editorInit(exportDownloads, true), newline_behavior: 'linebreak'}}
              initialValue={
                transcription && transcription[0]
                  ? transcription[0].textPlain
                  : ""
              }
              onCopy={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
              onKeyDown={(event) => {
                if ((event.ctrlKey || event.metaKey) && event.key === "c") {
                  event.preventDefault();
                  alert("Não é possivel copiar esse texto");
                }
              }}
              value={tinyText}
              onEditorChange={(content) => {
                const cleaned = sanitizeHtml(content, {
                  allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ]),
                  allowedAttributes: {'img': ['src']},
                  allowedSchemes: [ 'data', 'http', 'https'],
                });
                setTinyText(cleaned);
              }}
            />
          </Fragment>
        ) : (
          <StatusContainer>
            {(status === 0 || status !== null) && status >= 0 ? (
              <ContainerStatus>{TranscriptionStatus(status)}</ContainerStatus>
            ) : (
              <>
                <OptionListContainer>
                  {optionList.map((item) => (
                    <ContainerItem
                      key={item?.id}
                      onClick={() => {
                        setTranscriptionSelect(
                          item.type === transcriptionSelect ? "" : item.type
                        );
                        if (item.type === "automatico") setShowModelInput(true);
                        else setShowModelInput(false);
                      }}
                    >
                      <Circle selected={transcriptionSelect === item.type} />
                      <ItemTitle>{item.value}</ItemTitle>
                    </ContainerItem>
                  ))}
                </OptionListContainer>
                {showModelInput && (
                  <ContainerTemplate>
                    <DropdownGlobal
                      labelName="Modelo"
                      options={modelList}
                      defaultValue={
                        selectTemplate
                          ? selectTemplate?.name
                          : selectedModel?.name
                      }
                      showDefaultValue
                      width="320px"
                      setSelected={setSelectTemplate}
                      wantAnObject
                      disabled={blockChangeTemplate.current}
                    />
                  </ContainerTemplate>
                )}
              </>
            )}
          </StatusContainer>
        )}
      </ContainerTiny>
      <FooterContainer>
        <ButtonContainer>
          <Button
            disabled={statusError && !isSynchronizedTextEnabled ? false : disabledButton}
            onClick={disabledButton ? () => {} : statusError ? tryAgain : buttonFunction}>
            {statusError ? "Tentar novamente" : buttonTitle}
          </Button>
        </ButtonContainer>
      </FooterContainer>
    </Container>
  );
};

export default Transcription;
